
import { getUsers } from "@/services/user";
import { getRoles } from "@/services/roles";
import { Vue, Component } from "vue-property-decorator";
import { createProfile, getProfiles, updateProfile } from "@/services/profiles";
import { orderByName } from "@/utils/sort";

@Component({})
export default class Profile extends Vue {
  $refs!: any;
  active = true;
  dialog = false;
  dialogUsers = false;
  loading = false;
  loadingTable = true;
  apps: any = [];
  allRoles: any = [];
  allUsers: any = [];
  search: any = "";

  profiles: any = [];

  currentId = "";
  name = "";
  searchUser: any = "";
  roles: any = [];
  users: any = [];
  usersSelected: any = [];
  backupUsers: any = [];

  createForm = false;

  headers = [
    {
      text: "",
      align: "center",
      sortable: false,
      value: "active",
      width: "1%",
    },
    {
      text: "Nome",
      align: "start",
      sortable: false,
      value: "name",
    },
    {
      text: "Permissões",
      align: "center",
      sortable: false,
      value: "apps",
      width: "20%",
    },
    {
      text: "Usuários",
      align: "center",
      sortable: false,
      value: "users",
      width: "20%",
    },
  ];

  get usersFiltered() {
    this.users = this.usersSelected;
    let users = this.allUsers.filter((item: any) => {
      return item.name.toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1;
    });
    return users;
  }

  async created(): Promise<void> {
    getUsers()
      .then((res: any) => {
        this.allUsers = res.data;
        this.allUsers = orderByName(this.allUsers);
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os usuários!",
        });
      });
    getRoles(null)
      .then((res: any) => {
        this.allRoles = res.data;
        this.allRoles.map((item: any) => {
          item.name =
            item.app === null ? "Auth - " + item.name : item.app.name + " - " + item.name;
        });
        this.allRoles = orderByName(this.allRoles);
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar as permissões!",
        });
      });
    await getProfiles()
      .then((res: any) => {
        this.profiles = res.data;
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os perfis!",
        });
      });
    this.loadingTable = false;

    if (Object.keys(this.$route.query).length !== 0) {
      let id: any = atob(this.$route.query.id + "");
      this.setProfileDataOnForm(
        this.profiles.find((p: any) => p.id == id),
        this.$route.query.isUsers
      );
    }
  }

  setProfileDataOnForm(profile: any, isUsers: any = false): void {
    this.addfilters(profile.id, isUsers);

    this.createForm = false;
    this.name = profile.name;
    this.currentId = profile.id;
    this.active = profile.active;

    profile.roles.forEach((r: any) => this.roles.push(r.id));
    profile.users.forEach((u: any) => this.users.push(u.id + ""));

    if (isUsers) {
      this.usersSelected = [...this.users];

      this.allUsers.sort((a: any, b: any) => {
        const indexA = this.usersSelected.indexOf(a.id);
        const indexB = this.usersSelected.indexOf(b.id);

        // Se um dos usuários está em selectedUsers, coloca ele primeiro
        if ((indexA === -1 && indexB === -1) || (indexA > -1 && indexB > -1)) {
          const first = a.name.toLowerCase();
          const second = b.name.toLowerCase();
          if (first < second) return -1
          if (first > second) return 1
          return 0
        } 
        if (indexA === -1) return 1; // Se 'a' não está em selectedUsers, 'b' vem antes
        if (indexB === -1) return -1; // Se 'b' não está em selectedUsers, 'a' vem antes
      })
    }

    isUsers ? (this.dialogUsers = true) : (this.dialog = true);
  }

  roleIsInProfile(role_id: string | number): boolean {
    for (let role of this.roles) {
      if (role.id == role_id) return true;
    }
    return false;
  }

  userIsInProfile(user_id: string | number): boolean {
    for (let user of this.users) {
      if (user.id == user_id) return true;
    }
    return false;
  }

  openCreateModal(): void {
    this.dialog = true;
    this.createForm = true;
  }

  closeModal(isUsers = false): void {
    if (this.$route.fullPath != this.$route.path) this.setQuery();
    setTimeout(() => {
      this.name = "";
      this.searchUser = "";
      this.roles = [];
      this.users = [];
      this.usersSelected = [];
      this.active = true;
      if (!isUsers) this.$refs.form.reset();
    }, 290);
    isUsers ? (this.dialogUsers = false) : (this.dialog = false);
  }

  /*
   * Atualiza perfil e cria o que defino o tipo do metodo é
   * o atributo createForm (se for true é para criar um novo perfil)
   */
  update(isUsers = false): void {
    if (!isUsers && !this.$refs.form.validate()) return;
    this.loading = true;

    const id = this.currentId;
    const payload = {
      active: this.active,
      name: this.name,
      roles: this.roles.map((role: any) => (role.id ? role.id : role)),
      users: this.users,
    };

    this.createForm
      ? createProfile(payload)
          .then((res: any) => {
            if (res.status == 201) this.closeModal();
            const updatedData = {
              id: res.data.id,
              name: res.data.name,
              active: res.data.active,
              roles: res.data.roles.map((role: any) => {
                return { id: role };
              }),
              users: res.data.users.map((user: any) => {
                return { id: user };
              }),
            };
            this.profiles.push(updatedData);
            this.$emit("snackbar", {
              message: "Criada com sucesso!",
            });
            this.closeModal();
          })
          .catch((error: any) => {
            console.info(error);
            this.$emit("snackbar", {
              message: "Erro ao criar!",
            });
          })
          .finally(() => (this.loading = false))
      : updateProfile(id, payload)
          .then((res: any) => {
            this.profiles.map((profile: any) => {
              if (profile.id == id) {
                profile.name = res.data.name;
                profile.roles = res.data.roles;
                profile.users = res.data.users;
                profile.active = res.data.active;
              }
            });

            this.$emit("snackbar", {
              message: "Editada com sucesso!",
            });
            this.closeModal(isUsers);
          })
          .catch((error: any) => {
            console.info(error);
            this.$emit("snackbar", {
              message: "Erro ao editar!",
            });
          })
          .finally(() => (this.loading = false));
  }

  addfilters(id: any, isUsers: boolean): void {
    if (this.$route.fullPath == this.$route.path) {
      let query: any = {
        id: btoa(id),
        isUsers: isUsers,
      };
      this.setQuery(query);
    }
  }

  setQuery(query: any = {}): void {
    let name: any = this.$route.name;
    this.$router.replace({
      name: name,
      query: query,
    });
  }

  remove(id: any): void {
    const index = this.roles.indexOf(id);
    if (index !== -1) {
      this.roles.splice(index, 1);
    }
  }

  changeUser(id: any): void {
    if (this.usersSelected.indexOf(id) > -1) {
      this.usersSelected.splice(this.usersSelected.indexOf(id), 1);
    } else {
      this.usersSelected.push(id);
    }
  }
}
