
import { mapActions } from "vuex";
import { Vue, Component, Watch } from "vue-property-decorator";
import { orderByName } from "@/utils/sort";
import { IUsersRequest } from "@/utils/interfaces";
import { getRoles } from "@/services/roles";
import { getProfiles } from "@/services/profiles";
import {
  createUser,
  updateUser,
  expirePassword,
  resetPassword,
  twoFA,
} from "@/services/user";

@Component({
  filters: {
    formatDate(date: any) {
      if (date) {
        let newDate = new Date(date);
        let day: any = newDate.getDate();
        let month: any = newDate.getMonth() + 1;
        let year: any = newDate.getFullYear();
        let hours: any = newDate.getHours();
        let minutes: any = newDate.getMinutes();

        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;

        return day + "/" + month + "/" + year + " " + hours + ":" + minutes;
      }
      return "-- vazio --";
    },
  },
  methods: {
    ...mapActions("usersState", {
      getUsers: "actionsUsers",
    }),
  },
})
export default class Users extends Vue {
  // Dias para a expiração da senha
  PASSWORD_EXPIRATION = 60;

  search: any = "";
  minDate: any = "";
  createForm = false;
  showDisabledSsers = false;
  emailRules = [
    (v: any): any => !!v || "E-mail deve ser informado",
    (v: any): any => /.+@.+/.test(v) || "E-mail inválido | ex: pessoa@domínio.com",
  ];
  allRoles: any = [];
  allProfiles: any = [];
  menu = false;
  menu1 = false;
  $refs!: any;
  dialog = false;
  loadingTable = true;
  users = [] as Array<any>;
  getUsers: any;
  loading = false;
  headers = [
    { text: "", value: "name", align: "center", width: "1%", sortable: false },
    { text: "Nome", value: "name", align: "start", width: "30%" },
    { text: "E-mail", value: "email", align: "start" },
    {
      text: "Ult. Login",
      value: "last_login_at",
      align: "start",
      width: "15%",
    },
    { text: "2FA", value: "_2fa", align: "start", width: "15%" },
    { text: "IP", value: "last_login_ip", align: "start", width: "10%" },
    { text: "Permissões", value: "", align: "start", width: "1%", sortable: false },
    { align: "center", width: "3%", sortable: false },
  ];

  /* Variáveis do formulário */
  id = "";
  name = "";
  email = "";
  twoFA = false;
  active = false;
  force_login = false;
  password_due_date = "";
  dateFormatted: any = "";
  roles: any = [];
  profiles: any = [];

  get getItemsTable(): any {
    return this.users.filter((u) => {
      if (this.showDisabledSsers) return true;
      return u.active;
    });
  }

  @Watch("password_due_date")
  onPropertyChanged(value: string, oldValue: string): void {
    this.dateFormatted = this.formatDate(this.password_due_date);
  }

  async created(): Promise<void> {
    this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10));
    this.minDate = this.parseDate(this.formatDate(new Date().toISOString()));

    await this.getUsers()
      .then((users: IUsersRequest | any) => {
        this.users = users;
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os usuários!",
        });
      });

    this.loadingTable = false;

    await getRoles(["id", "name", "app"])
      .then((res: any) => {
        this.allRoles = res.data;
        this.allRoles.map((item: any) => {
          item.name =
            item.app === null ? "Auth - " + item.name : item.app.name + " - " + item.name;
        });
        this.allRoles = orderByName(this.allRoles);
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar as permissões!",
        });
      });

    await getProfiles()
      .then((res: any) => {
        this.allProfiles = res.data;
      })
      .catch((error: any) => {
        console.error(error);
        this.$emit("snackbar", {
          message: "Erro ao carregar os perfis!",
        });
      });

    if (Object.keys(this.$route.query).length !== 0) {
      let id: any = atob(this.$route.query.id + "");
      this.openModal(this.users.find((u: any) => u.id == id));
    }
  }

  setEmailIcon(email: string): string {
    const emailDomain = email.split("@")[1];
    return `https://www.google.com/s2/favicons?domain=${emailDomain}`;
  }

  openModal(item: any, createModal = false): void {
    this.dialog = true;
    this.createForm = createModal;
    if (!createModal) {
      this.addfilters(item.id);
      this.id = item.id;
      this.name = item.name;
      this.email = item.email;
      this.active = item.active;
      this.force_login = item.force_login;
      this.twoFA = ["TOTP", "SMS", "EMAIL"].includes(item._2fa);
      this.password_due_date = item.password_due_date
        ? item.password_due_date.split("T")[0]
        : "";

      this.roles = [...item.roles];
      this.profiles = [...item.profiles];
    } else {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      let date = new Date();
      date.setDate(date.getDate() + this.PASSWORD_EXPIRATION);

      let day: any = date.getDate();
      let month: any = date.getMonth() + 1;
      let year: any = date.getFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      console.log(year + "-" + month + "-" + day);

      this.password_due_date = year + "-" + month + "-" + day;
    }
  }

  closeModal(): void {
    if (this.$route.fullPath != this.$route.path) this.setQuery();
    setTimeout(() => {
      this.$refs.form.reset();
      this.id = "";
      this.password_due_date = "";
    }, 290);
    this.dialog = false;
  }

  expirePassword(id: string): void {
    expirePassword(id)
      .then(() => {
        this.$emit("snackbar", {
          message: "Senha foi expirada!",
        });
      })
      .catch((error: any) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao expirar senha!",
        });
      });
  }

  resetPassword(email: string): void {
    resetPassword(email)
      .then(() => {
        this.$emit("snackbar", {
          message: "Reset de senha foi enviado!",
        });
      })
      .catch((error: any) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao enviar reset de senha!",
        });
      });
  }

  _2FA(id: string): void {
    twoFA(id)
      .then((reponse: any) => {
        this.users.filter((user: any) => {
          if (user.id == id) {
            user._2fa = reponse.data._2fa;
          }
        });
        this.$emit("snackbar", {
          message: `2FA foi ${reponse.data._2fa !== null ? "ativado" : "desativado"}!`,
        });
      })
      .catch((error: any) => {
        console.info(error);
        this.$emit("snackbar", {
          message: "Erro ao enviar reset de senha!",
        });
      });
  }

  save(): void {
    if (!this.$refs.form.validate()) return;
    const payload: any = {
      id: this.id,
      name: this.name,
      email: this.email,
      _2fa: this.twoFA == true ? "TOTP" : null,
      active: this.active,
      force_login: this.force_login,
      password_due_date:
        `${this.parseDate(this.dateFormatted)}T00:00:00.000Z` == "T00:00:00.000Z"
          ? null
          : `${this.parseDate(this.dateFormatted)}T00:00:00.000Z`,
      roles: this.roles.map((role: any) => (role.id ? role.id : role)),
      profiles: this.profiles.map((profile: any) => (profile.id ? profile.id : profile)),
    };
    if (!payload._2fa) payload.totp_key = null;
    this.loading = true;
    this.createForm
      ? createUser(payload)
          .then((res: any) => {
            this.users.push(payload);
            this.getItemsTable;
            this.$emit("snackbar", {
              message: "Usuário foi criado!",
            });
            this.dialog = false;
            this.loading = false;
          })
          .catch((err: any) => {
            console.info(err);
            this.$emit("snackbar", {
              message: "Erro ao criar usuário!",
            });
            this.dialog = false;
            this.loading = false;
          })
      : updateUser(this.id, payload)
          .then((res: any) => {
            this.users.filter((user: any) => {
              if (user.id == payload.id) {
                user.name = res.data.name;
                user.email = res.data.email;
                user._2fa = res.data._2fa;
                user.active = res.data.active;
                user.force_login = res.data.force_login;
                user.password_due_date = res.data.password_due_date.split(" ")[0];
                user.roles = res.data.roles.map((role: any) => role);
                user.profiles = res.data.profiles.map((profile: any) => profile);
              }
              this.loading = false;
            });
            this.$emit("snackbar", {
              message: "Usuário foi atualizado!",
            });
            this.dialog = false;
          })
          .catch((err: any) => {
            console.info(err);
            this.$emit("snackbar", {
              message: "Erro ao atualizar usuário!",
            });
            this.dialog = false;
            this.loading = false;
          });
  }

  formatDate(date: any): string {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  parseDate(date: any): string {
    if (!date) return "";

    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  addfilters(id: any): void {
    if (this.$route.fullPath == this.$route.path) {
      let query: any = {
        id: btoa(id),
      };
      this.setQuery(query);
    }
  }

  setQuery(query: any = {}): void {
    let name: any = this.$route.name;
    this.$router.replace({
      name: name,
      query: query,
    });
  }

  remove(id: any, type: any): void {
    if(type === "role"){
      const index = this.roles.findIndex((role: any) => role.id === id);
      console.log("role");
      console.log(index);
      if (index !== -1) {
        this.roles.splice(index, 1);
      }
    }
    else if (type === "profile"){
      const index = this.profiles.findIndex((profile: any) => profile.id === id);
      console.log("profile");
      console.log(index);
      if (index !== -1) {
        this.profiles.splice(index, 1);
      }
    }
    
  }
}
